
.card {
  &-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  //display: flex;
  flex: 1 47%;
  box-sizing: border-box;
  //width: 100%;
  min-width: 5ch;
  margin: 5px;
  text-align: center;

  h5 {
    margin-bottom: 0;
  }

  a span {
    margin-right: 5px;
  }

  .bp3-card {
    padding: 10px;
  }
}